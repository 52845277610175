import React from "react"
import { graphql } from "gatsby"
import { Stack, Main, Sidebar } from "@layout"
import Sticky from "@components/Sticky"
import { Card as CardComponent } from "theme-ui"

import Divider from "@components/Divider"
import Seo from "@widgets/Seo"
import CardList from "../components/Blog/CardList"

import PostHead from "../components/BlogDetails/Post.Head"
import PostImage from "../components/BlogDetails/Post.Image"
import PostBody from "../components/BlogDetails/Post.Body"

import "./animation.css"

const styles = {
  text: {
    fontSize: `1.25rem`,
    textAlign: `center`,
  },
}

export const ServiceDetailsQuery = graphql`
  query ServiceDetailsMarkdowns($id: String!) {
    serviceData: allMarkdownRemark(filter: { id: { eq: $id } }) {
      nodes {
        id
        html
        frontmatter {
          title
          excerpt
          thumbnail
        }
        fields {
          slug
        }
      }
    }
    servicesData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "services" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          excerpt
        }
        fields {
          slug
        }
      }
    }
  }
`

const ServiceDetails = ({
  data: { serviceData, servicesData },
  pageContext: { services = {}, siteUrl } = {},
}) => {
  const {
    id,
    html,
    frontmatter: { excerpt, title, thumbnail },
    fields: { slug },
  } = serviceData.nodes[0]

  const service = {
    body: html,
    slug,
    excerpt,
    thumbnail,
    title,
    id,
  }

  const servicesAll = servicesData.nodes
    .filter(dataItem => dataItem.id !== serviceData.nodes[0].id)
    .map(dataItem => {
      const {
        id,
        frontmatter: { excerpt, title },
        fields: { slug },
      } = dataItem

      return {
        slug,
        excerpt,
        title,
        id,
        show: true,
      }
    })

  return (
    <>
      <Seo {...service} siteUrl={siteUrl} />
      <Divider space={3} />
      <div className="animateInDown">
        <PostHead {...service} />
      </div>
      <Divider space={1} />
      <div className="animateInUp">
        <Main>
          <CardComponent variant="paper" style={styles.wrapImage}>
            <PostImage {...service} inCard />
            <PostBody {...service} />
          </CardComponent>
          <Divider space={3} />
        </Main>
        <Sidebar>
          <Sticky>
            <CardList
              title="Services"
              nodes={[...servicesAll]}
              variant="horizontal-aside"
              limit={6}
              omitMedia
              omitCategory
              distinct
              aside
            />
          </Sticky>
        </Sidebar>
      </div>
      <Divider space={3} />
    </>
  )
}

export default ServiceDetails
